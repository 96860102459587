import React from "react";

function ErrorStatusBtn() {
  return (
    <div className="outPutStatus" id="error">
      Error
    </div>
  );
}

export default ErrorStatusBtn;
